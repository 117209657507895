<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Villa delle Ginestre garantisce l’accesso e la completa fruibilità degli spazi per tutti i portatori di Handicap e Disabili, grazie all’ assenza di barriere architettoniche.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/centrovilladelleginestre/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCCDaD18px_rdsppILzD3LWg" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li><a href="https://twitter.com/GinestreVilla" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/villa-delle-ginestre-s.r.l./" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://villadelleginestre.discentya.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://villadelleginestre.discentya.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Torino, 47/48<br>80040 Volla (NA)</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390817746647">Telefono: (+39) 081-7746647</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:villaginestresrl@libero.it">villaginestresrl@libero.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+390817746647">Lunedì-Venerdì<br>08:00 ÷ 20:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2023 Villa delle Ginestre - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2 d-none">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
