<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Via%20Torino,%2047/48%2080040%20Volla%20(NA)%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>La Storia</h2>
                    <p>
                        Villa delle Ginestre è l’evoluzione logica di un know how acquisito in più di 20 anni di attività ed è divenuta nel tempo sempre di più una risposta concreta per tutti i pazienti ed i loro familiari che hanno difficoltà a reperire strutture qualificate. Nel suo percorso l’azienda ha subito profonde trasformazioni e deve il proprio nome alla scelta di una struttura immersa in un campo di ginestre che, caso strano della vita, per varie ragioni non fù mai la sede del nostro progetto, che ebbe luogo con molta passione, in una piccola struttura a Somma Vesuviana.
                    </p>
                    <p>
                        Da subito il progetto ebbe notevole riscontro e dopo pochi anni ci spostammo in una sede più grande all’ interno di un centro commerciale, sito in Volla, che per ironia della sorte aveva lo stesso nome della nostra azienda. Nel 2004, è avvenuto il definitivo trasferimento nella sede attuale, per offrire una struttura all’ avanguardia, capiente e polifunzionale, nel pieno rispetto delle normative vigenti nel settore in cui lavorano da sempre professionisti di primissimo ordine che ci permettono di raggiungere livelli assistenziali di eccellenza.
                    </p>
                    <p>
                        Il centro Villa delle Ginestre è una struttura all’avanguardia, capiente e polifunzionale e presenta attrezzature, presidi e risorse tecnologiche atte allo svolgimento di tutte le prestazioni previste. La struttura garantisce l’accesso e la completa fruibilità degli spazi da parte di tutti gli utenti disabili, anche attraverso strutture specifiche (sistema Loges, per i non vedenti, e mappe visuo-tattili con linguaggio Braille).
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>La struttura</h2>
                    <p>
                        Le attrezzature del Centro sono qualitativamente e quantitativamente adeguate ai bisogni dell’utenza e garantiscono un corretto ed efficace svolgimento dei trattamenti richiesti.
                    </p>
                    <p>
                        In funzione degli scopi istituzionali e della tipologia dell’utenza sono previsti: Attrezzature, presidi e risorse tecnologiche atte allo svolgimento di prestazioni, fisioterapiche, logopediche, neuropsicomotorie, terapia occupazionale psicoterapiche; Attrezzature elettromedicali per la Terapia fisica di supporto all’esercizio terapeutico.
                    </p>
                    <p>
                        Villa delle Ginestre garantisce l’accesso e la completa fruibilità degli spazi per tutti i portatori di Handicap e Disabili, grazie all’ assenza di barriere architettoniche. Gli accessi dell’Istituto e l’ubicazione dei vari locali sono chiaramente indicati. Nella Struttura sono presenti una sala d’attesa ed un’area per l’accoglienza e l’informazione dell’Utenza.
                    </p>
                    <p>
                        Il centro è dotato di Servizi Sanitari e strutture specifiche per rendere agibile e confortevole per ogni spostamento con appositi percorsi tattili plantari per i non vedenti (Sistema Loges) e mappe visuo-tattili(Linguaggio Braille).
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Torino, 47/48<br>80040 Volla (NA)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+390817746647">(+39) 081-7746647</a></p>
                            <p>e-mail: <a href="mailto:villaginestresrl@libero.it">villaginestresrl@libero.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 08:00 - 20:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
